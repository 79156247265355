// Here you can add other styles
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap';

.cursor-pointer {
  cursor: pointer;
}

.report-row-header {
  background-color: whitesmoke;
}

.report-row-footer {
  border-top: 2px solid gray;
}

.popover {
  max-width: none;
}
